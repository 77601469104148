import React from 'react'
import { useModalAddChain } from '../../hooks/useModalAddChain'
import { isMobile } from 'react-device-detect'

const AddWalletMobile = () => {
  const { close, isOpenModal } = useModalAddChain()

  return (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        background: 'linear-gradient(#A8FFC0 0%, #78CBAC 40%)',
        zIndex: 1000,
        padding: 0,
        display: isOpenModal && isMobile ? 'block' : 'none'
      }}
    >
      <div
        style={{
          position: 'absolute',
          right: '5px',
          top: '10px'
        }}
        onClick={close}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.192 6.34375L11.949 10.5858L7.70697 6.34375L6.29297 7.75775L10.535 11.9998L6.29297 16.2418L7.70697 17.6558L11.949 13.4137L16.192 17.6558L17.606 16.2418L13.364 11.9998L17.606 7.75775L16.192 6.34375Z"
            fill="#949494"
          />
        </svg>
      </div>
      <img
        src={'/images/mobile_add_wallet.webp'}
        alt=""
        style={{
          zIndex: 50,
          margin: 'auto 0',
          width: '100%',
          height: '1005'
        }}
      />
    </div>
  )
}

export default AddWalletMobile
