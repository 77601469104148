import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 420px;
  width: 100%;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 8px 16px 0 ${({ theme }) => rgba(theme.black, 0.16)};
  border-radius: ${({ theme }) => theme.roundedLg};
  padding: 1.5rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    box-shadow: none;
  `};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
