import { ChainId, ETHER } from '@uniswap/sdk'
import { useActiveWeb3React } from './index'
import { useCurrencyBalance, useTokenBalance } from '../state/wallet/hooks'
import { mul } from '../utils/precisionCalculate'
import { useTokenPrices } from '../state/exchange/hooks'
import { USDT, USDC, SIPHER } from '../constants/tokens'

/**
 * This hooks to retrieving default currencies
 * used in Wallet detail modal (header)
 */
export function useCurrency() {
  const { account, chainId } = useActiveWeb3React()
  const ethBalance = useCurrencyBalance(account ?? undefined, ETHER)
  const exchangeRate = useTokenPrices()

  const usdt = useTokenBalance(account ?? undefined, USDT[chainId || ChainId.MAINNET])
  const usdc = useTokenBalance(account ?? undefined, USDC[chainId || ChainId.MAINNET])
  const sipher = useTokenBalance(account ?? undefined, SIPHER[chainId || ChainId.MAINNET])

  return [
    {
      icon: '/currencies/Sipher.png',
      name: 'SIPHER',
      value: sipher?.toSignificant(4) || '0',
      dollarValue: mul(sipher?.toSignificant(4) || 0, exchangeRate[sipher?.currency.symbol || 'SIPHER'])
    },
    {
      icon: '/currencies/ETH.png',
      name: 'ETH',
      value: ethBalance?.toSignificant(4) || '0',
      dollarValue: mul(ethBalance?.toSignificant(4) || 0, exchangeRate[ethBalance?.currency.symbol || 'ETH'])
    },
    {
      icon: '/currencies/USDT.png',
      name: 'USDT',
      value: usdt?.toSignificant(4) || '0',
      dollarValue: mul(usdt?.toSignificant(4) || 0, exchangeRate[usdt?.currency.symbol || 'USDT'])
    },
    {
      icon: '/currencies/USDC.png',
      name: 'USDC',
      value: usdc?.toSignificant(4) || '0',
      dollarValue: mul(usdt?.toSignificant(4) || 0, exchangeRate[usdt?.currency.symbol || 'USDC'])
    }
  ]
}
