import React from 'react'
import { TransactionDetails } from '../../state/transactions/reducer'
import styled from 'styled-components'
import { Text } from 'rebass'
import { formatTimestamp, getEtherscanLink } from '../../utils'
import { useActiveWeb3React } from '../../hooks'

import PendingIcon from '../../assets/images/ic-pending.png'
import SuccessIcon from '../../assets/images/ic-successful.png'
import FailedIcon from '../../assets/images/ic-failed.png'
import { AutoRow } from '../../components/Row'

const Wrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: between;
  width: 100%;
  padding-top: 1rem;
  border-top: 1px solid ${({ theme }) => theme.shade200};
  margin-bottom: 1rem;
  gap: 1rem;
  padding-right: 1rem;
  text-decoration: none;
`

const Summary = styled(Text)`
  font-size: 14px;
  line-height: 17.5px;
  color: ${({ theme }) => theme.shade900};
  font-weight: 700;
  font-family: 'Helvetica';
`

const Hash = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.shade500};
  line-height: 17.5px;
  width: full;
  word-wrap: no-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Date = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.shade500};
  line-height: 17.5px;
`

const StatusIcon = styled.img``

interface ActivityProps {
  tx: TransactionDetails
}

export default function Activity({ tx }: ActivityProps) {
  const { chainId } = useActiveWeb3React()
  let status = <StatusIcon src={PendingIcon} />
  if (tx.receipt) {
    // Success
    if (tx.receipt.status === 1 || typeof tx.receipt?.status === 'undefined') {
      status = <StatusIcon src={SuccessIcon} />
    } else {
      status = <StatusIcon src={FailedIcon} />
    }
  }

  
  return (
    <>
    {chainId && (
      <Wrapper href={getEtherscanLink(chainId, tx.hash, 'transaction')} target="_blank">
        {status}
        <AutoRow>
          <Summary>{tx.summary}</Summary>
          <Hash>{tx.hash}</Hash>
        </AutoRow>
        <Date>{formatTimestamp(tx.addedTime)}</Date>
      </Wrapper>
    )}
    </>
  )
}