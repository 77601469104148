import styled from 'styled-components'
import { ButtonSecondary } from '../Button'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { Text } from 'rebass'

export const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
  color: ${({ theme }) => theme.purple500};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
  align-items: center;
  gap: 0.25rem;
`

export const Container = styled.div`
  position: relative;
  padding: 1rem 1rem 0 1rem;
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

export const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

export const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`

export const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const Tabs = styled.div``
export const TabsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.25rem;
`
export const TabsContent = styled.div``

export const TabContainer = styled.div<{ active: boolean }>`
  padding: 1rem 0;
`
export const TabTitle = styled.div<{ active: boolean }>`
  padding: 0.5rem 1rem 0.5rem 0;
  text-align: center;
  font-weight: ${({ active }) => (active ? '700' : '400')};
  font-size: 14px;
  line-height: 17.5px;

  :hover {
    cursor: pointer;
    font-weight: 700;
  }
`

export const TokenAmountList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const TokenAmountItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TokenAmountCurrency = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`
export const TokenAmountCurrencyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const TokenAmount = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
export const TokenAmountSymbol = styled(Text)`
  color: ${({ theme }) => theme.shade700}
  text-transform: uppercase;
`

// New Updates

export const Spliter = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.shade150};
  margin-top: 0.5rem;
`

export const SectionTitle = styled.h3`
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  font-family: 'Helvetica';
  color: ${({ theme }) => theme.shade500};
`

export const NetworkGroup = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  cursor: pointer;
  width: max-content;
  & > h5 {
    color: ${({ theme }) => theme.shade900};
    font-size: 16px;
    line-height: 17.5px;
    font-weight: 700;
    font-family: 'Helvetica';
    margin: 0;
  }
`
export const CurrenciesGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
export const Currency = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const CurrencyNameGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`
export const CurrencyName = styled.span`
  font-family: 'Helvetica';
  font-weight: 700;
  font-size: 14px;
  line-height: 17.5px;
  color: ${({ theme }) => theme.shade900};
`

export const CurrencyAmount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
`

export const Navigations = styled.div``
export const Navigator = styled.a`
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: ${({ theme }) => theme.white}
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  gap: 10px;
  font-size: 1rem;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.shade900};
  font-family: 'Helvetica';
  text-decoration: none;
`
