import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const ALREADY_SEEN_ADD_CHAIN_POPUP_KEY = 'add-chain-popup-key'

interface MyState {
  isStorageSeen: boolean
  isOpenModal: boolean
  open: () => void
  close: () => void
}

export const useModalAddChain = create<MyState>()(
  persist(
    (set, get) => ({
      isStorageSeen: false,
      isOpenModal: false,
      open: () => {
        if (get().isStorageSeen) return
        set({ isOpenModal: true, isStorageSeen: true })
      },
      close: () => set({ isOpenModal: false })
    }),
    {
      name: ALREADY_SEEN_ADD_CHAIN_POPUP_KEY,
      partialize: state => ({
        isStorageSeen: state.isStorageSeen
      })
    }
  )
)
