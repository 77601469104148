import React from 'react'
import { isMobile } from 'react-device-detect'

import {
  HeaderFrame,
  HeaderRowBetween,
  HeaderElement,
  Title,
  AccountElement,
  HeaderControls,
  HeaderNav,
  HeaderNavLink
} from './style'

import FunkiLogo from '../../assets/svg/FunkiLogo.svg'
import { useActiveWeb3React } from '../../hooks'
import Web3Status from '../Web3Status'
import { MobileNav } from './MobileNav'
import { EXTERNAL_LINKS } from '../../constants/links'

export default function Header() {
  const { account } = useActiveWeb3React()
  const navs = [
    EXTERNAL_LINKS.funZone,
    EXTERNAL_LINKS.bridge,
    EXTERNAL_LINKS.swap,
    EXTERNAL_LINKS.town,
    EXTERNAL_LINKS.docs,
  ]

  return (
    <HeaderFrame id="headerFrame">
      <HeaderRowBetween id="headerRowBetween">
        <HeaderElement id="headerElement">
          <Title href="https://funkichain.sipher.gg/" target="__blank">
            <img src={FunkiLogo} alt="Funki Swap" />
          </Title>
        </HeaderElement>

        {!isMobile && (
          <>
            <HeaderNav>
              {navs.map(nav => (
                <HeaderNavLink key={nav.label} href={nav.href} active={nav.label === 'Swap'}>
                  {nav.label}
                </HeaderNavLink>
              ))}
            </HeaderNav>
          </>
        )}

        <HeaderControls id="headerControl">
          <HeaderElement>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              <Web3Status />

              {isMobile && <MobileNav />}
            </AccountElement>
          </HeaderElement>
        </HeaderControls>
      </HeaderRowBetween>
    </HeaderFrame>
  )
}
