import styled from 'styled-components'
import { linearGradient } from 'polished'

import Donuts from '../../assets/images/donuts.png'

export const PoolModalHeader = styled.div`
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: relative;
  background-color: ${linearGradient({ colorStops: ['#C6A4FF 30%', '#67FFDB 100%'], toDirection: 'to right' })};

  :before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 24px;
    width: 180px;
    height: 100px;
    background-size: cover;
    background-position: center;
    background-image: url(${Donuts});
    z-index: 0;
  }
`

export const ModalHeader = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 2rem;
  align-items: flex-start;
  justify-content: center;
  color: ${({ theme }) => theme.white};
`

export const PoolModalContentWrapper = styled.div`
  width: 100%;
  padding: 2rem;
`

export const PoolModalContent = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.shade150};
  border-radius: ${({ theme }) => theme.rounded};
`

export const Splitter = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.shade150};
`
