import styled from 'styled-components'
import { Text } from 'rebass'
import { RowBetween } from '../Row'

export const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100%);
    position: relative;
  `};
  background-color: ${({ theme }) => theme.purple500};
`

export const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.5rem;
`};
`

export const HeaderNav = styled.div`
  display: flex;
  flex-direction: row;

  & > * + * {
    margin-left: 2.5rem;
  }
`

export const HeaderNavLink = styled.a<{ active: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: 'NeueMachina';
  color: ${({ active, theme }) => (active ? theme.secondaryText1 : theme.white)};
  font-weight: 800;
  font-size: 1rem;
  line-height: 20px;
  :hover {
    cursor: pointer;
  }
`

export const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`

export const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.rounded};
  white-space: nowrap;
  width: 100%;
  color: ${({ theme }) => theme.white};
  position: relative;
`

export const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

export const NetworkCard = styled.div`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
  font-size: 1rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.white}
  font-family: 'NeueMachina';
  font-weight: 800;
`

export const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    align-items: flex-end;
  `};
`

export const TextLogo = styled(Text)`
  font-family: 'NeueMachina';
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.secondaryText1}
    ${({ theme }) => theme.mediaWidth.upToSmall`
    color: ${theme.white}
  `};
`

export const HeaderRowBetween = styled(RowBetween)`
  align-items: center;
  padding: 1rem 1.5rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    align-items: center;
    padding: 1rem 0.8rem;
  `};
`

export const MenuBtn = styled.button`
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: ${({ theme }) => theme.rounded};
`
