import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { NavLink, Link as HistoryLink } from 'react-router-dom'

import { ArrowLeft } from 'react-feather'
import { RowBetween } from '../Row'
import QuestionHelper from '../QuestionHelper'

import SettingIconSVG from '../../assets/svg/bx-slider-alt.svg'

const activeClassName = 'ACTIVE'

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
  gap: 1rem;
`

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: ${({ theme }) => theme.roundedLg};
  justify-content: space-evenly;
  background-color: ${({ theme }) => theme.shade50};
  padding: 0.25rem;
  width: 100%;

  :has(> a:not(.${activeClassName}):hover) {
    background-color: ${({ theme }) => theme.shade100};
  }
`

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryText1};
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  width: 50%;
  font-family: 'NeueMachina';

  &.${activeClassName} {
    color: ${({ theme }) => theme.secondaryText1};
    background-color: ${({ theme }) => theme.purple500};
  }

  &.${activeClassName}:hover {
    background-color: ${({ theme }) => theme.purple500};
  }

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.shade100};
  }
`

const ActiveText = styled.div`
  font-family: 'NeueMachina';
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryText1};
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

const IconWrapper = styled(NavLink)`
  display: flex;
  padding: 0.5rem;
  border-radius: ${({ theme }) => theme.roundedLg};

  img {
    width: 24px;
    height: auto;
  }

  :hover {
    background-color: ${({ theme }) => theme.shade100};
  }
`

function SettingIcon() {
  return (
    <IconWrapper to={'/settings'} id={`settings-nav-link`}>
      <img src={SettingIconSVG} alt="settings" />
    </IconWrapper>
  )
}

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  const { t } = useTranslation()
  return (
    <TabsWrapper>
      <Tabs>
        <StyledNavLink id={`swap-nav-link`} to={'/swap'} isActive={() => active === 'swap'}>
          {t('swap')}
        </StyledNavLink>
        <StyledNavLink id={`pool-nav-link`} to={'/pool'} isActive={() => active === 'pool'}>
          {t('pool')}
        </StyledNavLink>
      </Tabs>
      <SettingIcon />
    </TabsWrapper>
  )
}

export function FindPoolTabs() {
  return (
    <Tabs style={{ marginBottom: '1rem' }}>
      <RowBetween style={{ padding: '0.25rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>Import Pool</ActiveText>
        <QuestionHelper text={"Use this tool to find pairs that don't automatically appear in the interface."} />
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({ adding }: { adding: boolean }) {
  return (
    <Tabs style={{ marginBottom: '1rem' }}>
      <RowBetween style={{ padding: '0.25rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{adding ? 'Add' : 'Remove'} Liquidity</ActiveText>
        <QuestionHelper
          text={
            adding
              ? 'When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.'
              : 'Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.'
          }
        />
      </RowBetween>
    </Tabs>
  )
}
