import React, { useRef } from 'react'
import styled from 'styled-components'
import { ExternalLink } from '../../theme'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import useToggle from '../../hooks/useToggle'
import { MenuBtn } from './style'
import { ReactComponent as MenuIconSVG } from '../../assets/svg/bx-menu.svg'
import { EXTERNAL_LINKS } from '../../constants/links'
import { rgba } from 'polished'

const MobileMenuWrapper = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MobileMenu = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  width: 10rem;
  border-radius: 0.75rem;
  padding: 1rem;
  box-shadow: 0px 8px 16px 0 ${({ theme }) => rgba(theme.black, 0.16)};
`

const MenuItem = styled(ExternalLink)`
  font-family: 'Helvetica';
  font-weight: 700;
  font-size: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.shade100};
  text-decoration: none;
  color: ${({ theme }) => theme.shade900};
  padding: 0.75rem 0;

  &:focus {
    text-decoration: none;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  &:first-child {
    padding-top: 0;
  }
`

export function MobileNav() {
  const node = useRef<HTMLDivElement>()
  const [open, toggle] = useToggle(false)
  const navs = [
    EXTERNAL_LINKS.funZone,
    EXTERNAL_LINKS.bridge,
    EXTERNAL_LINKS.swap,
    EXTERNAL_LINKS.town,
    EXTERNAL_LINKS.docs,
  ]

  useOnClickOutside(node, open ? toggle : undefined)
  return (
    <MobileMenuWrapper ref={node as any}>
      <MenuBtn onClick={toggle}>
        <MenuIconSVG />
      </MenuBtn>
      {open && (
        <MobileMenu>
          {navs.map((item, index) => (
            <MenuItem key={index} href={item.href}>
              {item.label}
            </MenuItem>
          ))}
        </MobileMenu>
      )}
    </MobileMenuWrapper>
  )
}
