import { ChainId } from '@uniswap/sdk'
import { Token } from '../types/token'

// List of supported tokens by default

export const USDT = {
  [ChainId.FUNKI_TESTNET]: new Token(
    ChainId.FUNKI_TESTNET,
    '0x994b3C5A349c2b5c2d6644256c344c1818aD4fB8',
    6,
    'USDT',
    'Tether USD',
    '/currencies/USDT.png'
  ),
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    6,
    'USDT',
    'Tether USD',
    '/currencies/USDT.png'
  ),
  [ChainId.GÖRLI]: undefined,
  [ChainId.ROPSTEN]: undefined,
  [ChainId.RINKEBY]: undefined,
  [ChainId.KOVAN]: undefined
}

export const USDC = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    6,
    'USDC',
    'USD//C',
    '/currencies/USDC.png'
  ),
  [ChainId.FUNKI_TESTNET]: new Token(
    ChainId.FUNKI_TESTNET,
    '0x243eD74B2fFD6BeD1AAE9CAec6722fBcE68d5cF8',
    6,
    'USDC',
    'USD//C',
    '/currencies/USDC.png'
  ),
  [ChainId.GÖRLI]: undefined,
  [ChainId.ROPSTEN]: undefined,
  [ChainId.RINKEBY]: undefined,
  [ChainId.KOVAN]: undefined
}

export const SIPHER = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x9F52c8ecbEe10e00D9faaAc5Ee9Ba0fF6550F511',
    18,
    'SIPHER',
    'Sipher Token',
    '/currencies/SIPHER.png'
  ),
  [ChainId.FUNKI_TESTNET]: new Token(
    ChainId.MAINNET,
    '0xd7116A9d60E963268355C6b8B2d2Fb91Ab7293E5',
    18,
    'SIPHER',
    'Sipher Token',
    '/currencies/SIPHER.png'
  ),
  [ChainId.GÖRLI]: undefined,
  [ChainId.ROPSTEN]: undefined,
  [ChainId.RINKEBY]: undefined,
  [ChainId.KOVAN]: undefined
}
