import { createReducer } from '@reduxjs/toolkit'
import { updatePrices } from './actions'

interface ExchangeState {
  prices: Record<string, string>
}

const initialState: ExchangeState = {
  prices: {}
}

export default createReducer(initialState, builder =>
  builder.addCase(updatePrices, (state, action) => {
    state.prices = {
      ...action.payload,
      FUN20: '1000' // Fixed price
    }
  })
)
