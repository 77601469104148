import React, { useMemo } from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme
} from 'styled-components'
import { useIsDarkMode } from '../state/user/hooks'
import { Text, TextProps } from 'rebass'
import { Colors, FunkiColors } from './styled'
import backgroundImageMb from '../assets/images/dex_bg_mb_2.webp'
import backgroundImage from '../assets/images/dex-bg-static_x1.webp'

export * from './components'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 600,
  upToMedium: 960,
  upToLarge: 1280
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

export const funkiColors: FunkiColors = {
  // Primary
  primary0: white,
  primary50: '#FFE8F3',
  primary100: '#FFD2E8',
  primary150: '#FFBBDC',
  primary200: '#FFA5D0',
  primary300: '#FF78B9',
  primary400: '#FF4BA1',
  primary500: '#FF1E8A',
  primary600: '#CC186E',
  primary700: '#991253',
  primary800: '#660C37',
  primary850: '#4C0929',
  primary900: '#33061C',
  primary950: '#19030E',
  primary1000: black,

  // Secondary
  secondary0: '#FDFFF1',
  secondary50: '#F9FFE6',
  secondary100: '#F2FFCD',
  secondary150: '#ECFFB4',
  secondary200: '#E5FF9B',
  secondary300: '#D9FE68',
  secondary400: '#CCFE36',
  secondary500: '#BFFE04',
  secondary600: '#99CB03',
  secondary700: '#739802',
  secondary800: '#4C6602',
  secondary850: '#394C01',
  secondary900: '#263301',
  secondary950: '#131900',
  secondary1000: black,

  // Shade
  shade0: white,
  shade50: '#F4F4F4',
  shade100: '#EAEAEA',
  shade150: '#DFDFDF',
  shade200: '#D4D4D4',
  shade300: '#BFBFBF',
  shade400: '#A9A9A9',
  shade500: '#949494',
  shade600: '#767676',
  shade700: '#595959',
  shade800: '#3B3B3B',
  shade850: '#2C2C2C',
  shade900: '#1E1E1E',
  shade950: '#0F0F0F',
  shade1000: black,

  // Sky
  sky0: white,
  sky50: '#F1FAFD',
  sky100: '#E4F6FA',
  sky150: '#D6F1F8',
  sky200: '#C8ECF5',
  sky300: '#ADE3F1',
  sky400: '#91D9EC',
  sky500: '#76D0E7',
  sky600: '#60AEC2',
  sky700: '#4A8C9D',
  sky800: '#346A77',
  sky850: '#295965',
  sky900: '#1E4852',
  sky950: '#133740',
  sky1000: '#08262D',

  // Red
  red0: white,
  red50: '#FEEBEB',
  red100: '#FCD8D8',
  red150: '#FBC4C4',
  red200: '#F9B1B0',
  red300: '#F78989',
  red400: '#F46261',
  red500: '#F13B3A',
  red600: '#C12F2E',
  red700: '#922323',
  red800: '#621817',
  red850: '#4A1211',
  red900: '#330C0C',
  red950: '#1B0606',
  red1000: '#030000',

  // Yellow
  yellow0: white,
  yellow50: '#FEFEE5',
  yellow100: '#FDFDCC',
  yellow150: '#FCFBB2',
  yellow200: '#FBFA99',
  yellow300: '#F8F866',
  yellow400: '#F6F533',
  yellow500: '#F4F300',
  yellow600: '#C3C200',
  yellow700: '#929200',
  yellow800: '#626100',
  yellow850: '#494900',
  yellow900: '#313100',
  yellow950: '#181800',
  yellow1000: black,

  // Purple
  purple0: white,
  purple50: '#F0E7FF',
  purple100: '#E1CEFF',
  purple150: '#D2B6FF',
  purple200: '#C29EFF',
  purple300: '#A46DFE',
  purple400: '#863DFE',
  purple500: '#680CFE',
  purple600: '#530ACB',
  purple700: '#3E0798',
  purple800: '#2A0566',
  purple850: '#1F044C',
  purple900: '#150233',
  purple950: '#0A0119',
  purple1000: black
}

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: darkMode ? '#FFFFFF' : '#000000',
    text2: darkMode ? '#C3C5CB' : '#565A69',
    text3: darkMode ? '#6C7284' : '#888D9B',
    text4: darkMode ? '#565A69' : '#C3C5CB',
    text5: darkMode ? '#2C2F36' : '#EDEEF2',

    // backgrounds / greys
    bg1: darkMode ? '#212429' : '#FFFFFF',
    bg2: darkMode ? '#2C2F36' : '#F7F8FA',
    bg3: darkMode ? '#40444F' : '#EDEEF2',
    bg4: darkMode ? '#565A69' : '#CED0D9',
    bg5: darkMode ? '#6C7284' : '#888D9B',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.7)' : 'rgba(0,0,0,.7)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

    //primary colors
    // primary1: darkMode ? '#2172E5' : '#ff007a', // Legacy
    primary1: funkiColors.purple500,
    primary2: darkMode ? '#3680E7' : '#FF8CC3',
    primary3: darkMode ? '#4D8FEA' : '#FF99C9',
    primary4: funkiColors.purple100,
    primary5: funkiColors.purple50,

    // color text
    // primaryText1: darkMode ? '#6da8ff' : '#ff007a', // Legacy
    primaryText1: funkiColors.purple500,
    secondaryText1: funkiColors.secondary500,

    // secondary colors
    secondary1: darkMode ? '#2172E5' : '#ff007a',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    // other
    // Legacy. Keep it for reference
    // red1: '#FF6871',
    // red2: '#F82D3A',
    // green1: '#27AE60',
    yellow1: '#FFE270',
    yellow2: '#F3841E',
    red1: funkiColors.red500,
    red2: funkiColors.red600,
    green1: funkiColors.secondary600

    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),
    ...funkiColors,

    grids: {
      sm: 8,
      md: 12,
      lg: 24
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
    bodyBg: backgroundImage,
    bodyBgMb: backgroundImageMb,
    rounded: '16px',
    roundedLg: '32px'
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  }
}

export const FixedGlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica/HelveticaNeueUltraLight.otf') format('opentype');
  font-weight: 100;
}
@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica/HelveticaNeueLight.otf') format('opentype');
  font-weight: 200;
}
@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica/HelveticaNeueThin.otf') format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica/Helvetica.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica/HelveticaNeueMedium.otf') format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica/HelveticaNeueRoman.otf') format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica/HelveticaNeueBold.otf') format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica/HelveticaNeueHeavy.otf') format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'Helvetica';
  src: url('/fonts/Helvetica/HelveticaNeueBlack.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: 'NeueMachina';
  font-weight: 300;
  src: url('/fonts/NeueMachina-Ultralight.otf') format('opentype');
}
@font-face {
  font-family: 'NeueMachina';
  font-weight: 400;
  src: url('/fonts/NeueMachina-Light.otf') format('opentype');
}
@font-face {
  font-family: 'NeueMachina';
  font-weight: 500;
  src: url('/fonts/NeueMachina-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'NeueMachina';
  font-weight: 600;
  src: url('/fonts/NeueMachina-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'NeueMachina';
  font-weight: 700;
  src: url('/fonts/NeueMachina-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'NeueMachina';
  font-weight: 800;
  src: url('/fonts/NeueMachina-Ultrabold.otf') format('opentype');
}
@font-face {
  font-family: 'NeueMachina';
  font-weight: 900;
  src: url('/fonts/NeueMachina-Black.otf') format('opentype');
}

html, input, textarea, button {
  font-family: 'Helvetica', sans-serif;
  letter-spacing: -0.018em;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Helvetica', sans-serif;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg2};
}

body {
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ theme }) =>
    `url(${theme.bodyBg})
    `};
  background-size: cover;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    background: #69B9DC;
    background-image: ${({ theme }) =>
      `url(${theme.bodyBgMb})
      `};
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
  `}
}

#root {
  width: 100%;
  height: 100%;
}
`
