import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { NavLink, useHistory } from 'react-router-dom'
import AppBody from '../AppBody'
import { ArrowLeft } from 'react-feather'
import { Wrapper } from '../../components/swap/styleds'
import { useAllTransactions } from '../../state/transactions/hooks'
import Activity from './Activity'
import { useActiveWeb3React } from '../../hooks'

const PageHeader = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryText1}

  :hover,
  :focus {
    color: ${({ theme }) => theme.purple700}
  }
`

const PageHeaderText = styled(Text)`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
`

const LogsWrapper = styled.div`
  height: 320px;
  overflow: hidden;
  overflow-y: auto;
`

const NoData = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.shade500};
  font-family: 'Helvetica';
`

// const StatusIcon = styled.div`
// `

export default function Activities() {
  const { account } = useActiveWeb3React()
  const history = useHistory()
  const allTx = useAllTransactions()
  const tx = allTx && account ?
    Object.values(allTx)
      .filter(tx => tx.from === account)
      .sort((a, b) => b.addedTime - a.addedTime) :
    []

  return (
    <>
      <AppBody>
        <PageHeader to={'#'} onClick={() => history.goBack()}>
          <ArrowLeft size={16} />
          <PageHeaderText>Activity Log</PageHeaderText>
        </PageHeader>
        <Wrapper id="activity-log">
          <LogsWrapper>
            {account && (tx.length > 0) ? tx.map(transaction => (
              <Activity tx={transaction} key={transaction.hash} />
            )) : (
              // TODO: fix me
              <NoData>
                <Text>There is no data</Text>
              </NoData>
            )}
          </LogsWrapper>
        </Wrapper>
      </AppBody>
    </>
  )
}