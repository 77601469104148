const funkiChainOrigin = process.env.REACT_APP_FUNKICHAIN_URL

export const EXTERNAL_LINKS = {
  portfolio: {
    label: 'Portfolio',
    href: `${funkiChainOrigin}/portfolio`
  },
  activities: {
    label: 'Activity Log',
    href: `${funkiChainOrigin}/bridge/log`
  },
  funZone: {
    label: 'FunZone',
    href: `${funkiChainOrigin}/funzone`
  },
  bridge: {
    label: 'Bridge',
    href: `${funkiChainOrigin}/bridge`
  },
  swap: {
    label: 'Swap',
    href: '#'
  },
  town: {
    label: 'Funki Town',
    href: `${funkiChainOrigin}/funki-town`
  },
  docs: {
    label: 'Docs',
    href: 'https://docs.funkichain.com'
  }
}
