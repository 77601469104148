import React, { useCallback, useContext } from 'react'
import {
  Container,
  CloseIcon,
  CloseColor,
  HeaderRow,
  Spliter,
  SectionTitle,
  NetworkGroup,
  CurrenciesGroup,
  Currency,
  CurrencyNameGroup,
  CurrencyName,
  CurrencyAmount,
  Navigations,
  Navigator
} from './styled'
import { ChainId } from '@uniswap/sdk'
import { useActiveWeb3React } from '../../hooks'
import { useWeb3React } from '@web3-react/core'
import { shortenAddress } from '../../utils'
import { ThemeContext } from 'styled-components'
import { ChevronDown } from 'react-feather'
import { useCurrency } from '../../hooks/useCurrency'
import { Image, Text } from 'rebass'
import { formatDollars } from '../../utils/currencyFormats'

import { ReactComponent as WalletIconSVG } from '../../assets/svg/bxs-wallet.svg'
import { ReactComponent as PortfolioIconSVG } from '../../assets/svg/bx-briefcase-alt.svg'
import { ReactComponent as LogoutIconSVG } from '../../assets/svg/bx-log-out-circle.svg'
import FunkiLogo from '../../assets/images/funki-logo.png'
import { EXTERNAL_LINKS } from '../../constants/links'

interface AccountDetailsProps {
  toggleWalletModal: () => void
}

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.FUNKI_TESTNET]: 'Funki Testnet'
}

const NETWORK_IMGS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.FUNKI_TESTNET]: FunkiLogo
}

export const HAS_BEEN_LOGGED_OUT = 'HasBeenLoggedOut'

export default function AccountDetails({ toggleWalletModal }: AccountDetailsProps) {
  const theme = useContext(ThemeContext)
  const { account, chainId, error } = useActiveWeb3React()
  const { deactivate } = useWeb3React()
  const currencies = useCurrency()

  const logout = useCallback(async () => {
    deactivate()
    const { ethereum } = window

    // Revoke permission, due to web3-react cannot disconnect completely
    if (ethereum) {
      await ethereum.request({
        'method': 'wallet_revokePermissions',
        'params': [
          {
            'eth_accounts': {}
          }
        ]
      })
    }
    
  }, [deactivate])

  const onClickLogout = async () => logout()

  return (
    <>
      <Container>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        <HeaderRow>
          <WalletIconSVG />
          {account && shortenAddress(account)}
        </HeaderRow>
        <Spliter />
        {/* Network */}
        <SectionTitle>Network</SectionTitle>
        <NetworkGroup>
          {!error && (
            <>
              {chainId && <img width={24} height={24} src={NETWORK_IMGS[chainId] ?? ''} alt="Funki" />}
              <h5>{chainId && NETWORK_LABELS[chainId]}</h5>
              <ChevronDown size={16} color={theme.shade900} />
            </>
          )}
        </NetworkGroup>
        <Spliter />
        {/*  Currencies */}
        <SectionTitle>Wallet Balance</SectionTitle>
        <CurrenciesGroup>
          {currencies.map(currency => (
            <Currency key={currency.name}>
              <CurrencyNameGroup>
                <div
                  style={{
                    position: 'relative'
                  }}
                >
                  <Image src={currency.icon} width={24} height={24} alt={currency.name} />
                  {chainId && (
                    <div
                      style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        borderRadius: '100%',
                        width: '10px',
                        height: '10px',
                        padding: '1px',
                        background: 'black'
                      }}
                    >
                      <img
                        style={{
                          width: '8px',
                          height: '8px',
                          position: 'absolute'
                        }}
                        src={NETWORK_IMGS[chainId] ?? ''}
                        alt=""
                      />
                    </div>
                  )}
                </div>
                <CurrencyName>{currency.name}</CurrencyName>
              </CurrencyNameGroup>
              <CurrencyAmount>
                <Text color={theme.shade900} fontSize={16} fontWeight={600}>
                  {currency.value}
                </Text>
                <Text color={theme.shade500} fontSize={16} fontWeight={400}>
                  {`(${formatDollars(currency.dollarValue)})`}
                </Text>
              </CurrencyAmount>
            </Currency>
          ))}
        </CurrenciesGroup>
        <Spliter />
        {/* Nav */}
        <Navigations>
          <Navigator href={EXTERNAL_LINKS.portfolio.href} target="_blank">
            <PortfolioIconSVG />
            {EXTERNAL_LINKS.portfolio.label}
          </Navigator>

          <Navigator onClick={() => onClickLogout()}>
            <LogoutIconSVG />
            Logout
          </Navigator>
        </Navigations>
      </Container>
    </>
  )
}
