import React, { useCallback, useEffect, useState } from 'react'
import Modal from '../Modal'
import styled from 'styled-components'
import { AutoColumn } from '../Column'
import { AutoRow, RowBetween } from '../Row'
import { ButtonError } from '../Button'
import { PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN } from '../../constants'

const KEYWORD = 'take it easy'

const ModalContainer = styled.div`
  max-width: 454px;
  width: 100%;
  padding: 2rem;
  background: ${({ theme }) => theme.white};
  border-radius: ${({ theme }) => theme.roundedLg};
  overflow: auto;
`

const Title = styled.h1`
  font-family: 'Helvetica';
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryText1};
  margin: 0;
`

const Paragraph = styled.p`
  color: ${({ theme }) => theme.shade700};
  font-family: 'Helvetica';
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
  margin: 0;
`

const GrayText = styled.span`
  color: ${({ theme }) => theme.shade500};
`

const ConfirmInput = styled.input`
  padding: 0.75rem 1rem;
  font-family: 'Helvetica';
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
  box-shadow: none;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.shade50};
  width: 100%;
  border-radius: ${({ theme }) => theme.rounded};
`

const ButtonGray = styled(ButtonError)`
  background-color: ${({ theme }) => theme.shade100};
  color: ${({ theme }) => theme.shade900};
  margin-right: 0.5rem;

  :hover,
  :focus {
    box-shadow: none;
    background-color: ${({ theme }) => theme.shade200};
  }
`

interface ConfirmHighImpactModalProps {
  isOpen: boolean
  onConfirm: (val: boolean) => void
}

export default function ConfirmHighImpactModal({ isOpen, onConfirm }: ConfirmHighImpactModalProps) {
  const [inp, setInp] = useState<string>('')
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
  const handleDismiss = useCallback(() => null, [])

  const handleInpChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInp(e.target.value)
  }, [])

  useEffect(() => {
    if (inp === KEYWORD) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
  }, [inp])

  return (
    <Modal isOpen={isOpen} onDismiss={handleDismiss} maxHeight={90}>
      <ModalContainer>
        <AutoColumn gap="1rem">
          <AutoRow>
            <Title>Mint token</Title>
          </AutoRow>
          <AutoRow>
            <Paragraph>
              This swap has a high price impact of at least {PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN.toFixed(0)}%. Please
              be aware and type &quot;take it easy&quot; to continue swapping your token
            </Paragraph>
          </AutoRow>
          <AutoRow>
            <ConfirmInput value={inp} onChange={handleInpChange} placeholder="take it easy" />
          </AutoRow>
          <AutoRow>
            <Paragraph>
              <GrayText>I agreed with the Funki’s Terms of Service</GrayText>
            </Paragraph>
          </AutoRow>
          <RowBetween>
            <ButtonGray
              onClick={() => {
                onConfirm(false)
              }}
            >
              Cancel
            </ButtonGray>

            <ButtonError
              disabled={btnDisabled}
              onClick={() => {
                onConfirm(true)
              }}
            >
              Confirm
            </ButtonError>
          </RowBetween>
        </AutoColumn>
      </ModalContainer>
    </Modal>
  )
}
