import React from 'react'
import { AutoColumn } from '../../components/Column'
import { Wrapper } from '../../components/swap/styleds'
import AppBody from '../AppBody'
import { useUserDeadline, useUserSlippageTolerance } from '../../state/user/hooks'
import TransactionSettings from '../../components/TransactionSettings'
import { Text } from 'rebass'
import styled from 'styled-components'
import { NavLink, useHistory } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'

const PageHeader = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  margin-bottom: 2rem;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryText1}

  :hover,
  :focus {
    color: ${({ theme }) => theme.purple700}
  }
`

const PageHeaderText = styled(Text)`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
`

export default function TransactionSettingsPage() {
  const history = useHistory()
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()
  const [deadline, setDeadline] = useUserDeadline()

  return (
    <>
      <AppBody>
        <PageHeader to={'#'} onClick={() => history.goBack()}>
          <ArrowLeft size={16} />
          <PageHeaderText>Transaction Settings</PageHeaderText>
        </PageHeader>
        <Wrapper id="settings-page">
          <AutoColumn gap={'md'}>
            <TransactionSettings
              rawSlippage={userSlippageTolerance}
              setRawSlippage={setUserslippageTolerance}
              deadline={deadline}
              setDeadline={setDeadline}
            />
          </AutoColumn>
        </Wrapper>
      </AppBody>
    </>
  )
}
