import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { darken } from 'polished'
import React, { useCallback, useMemo } from 'react'
import { Activity } from 'react-feather'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { NETWORK_CHAIN_ID, NETWORK_URL } from '../../connectors'
import { NetworkContextName } from '../../constants'
import useENSName from '../../hooks/useENSName'
import { useHasSocks } from '../../hooks/useSocksBalance'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import { isMobile } from 'react-device-detect'
import Loader from '../Loader'

import { RowBetween } from '../Row'

import { ReactComponent as WalletIconSVG } from '../../assets/svg/bxs-wallet.svg'
import WalletFlyout from '../WalletModal/WalletFlyout'
import { toHex } from '../../utils/hex'
import { useModalAddChain } from '../../hooks/useModalAddChain'

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: none;
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
  transition: background-color 0.25s ease-out;
`

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  background-color: ${({ theme }) => theme.purple400};
  border: none;
  color: ${({ theme }) => theme.white};
  border-radius: ${({ theme }) => theme.rounded};
  font-weight: 500;
  transition: all 0.15s ease-out;
  :hover,
  :focus {
    color: ${({ theme }) => theme.white}
    background-color: ${({ theme }) => theme.purple400};
    box-shadow: none;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.5rem;
  `}
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background-color: ${({ theme }) => theme.purple400};
  border: none;
  border-radius: ${({ theme }) => theme.rounded};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.purple400};
    box-shadow: none;
  }
  transition: background-color 0.25s ease-out;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.5rem;
  `}
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  font-family: 'NeueMachina';
  font-weight: 800;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

const SOCK = (
  <span role="img" aria-label="has socks emoji" style={{ marginTop: -4, marginBottom: -4 }}>
    🧦
  </span>
)

function Web3StatusInner() {
  const { t } = useTranslation()
  const { account, error } = useWeb3React()

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)

  const hasPendingTransactions = !!pending.length
  const hasSocks = useHasSocks()
  const toggleWalletModal = useWalletModalToggle()
  const { open, isStorageSeen } = useModalAddChain()

  const handleToggleWalletModal = () => {
    if (isMobile && !isStorageSeen) open()
    else toggleWalletModal()
  }

  const switchNetwork = useCallback(async () => {
    if (error && window.ethereum) {
      // Metamask injected
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: toHex(NETWORK_CHAIN_ID) }] // chainId must be in hexadecimal numbers
        })
      } catch (e) {
        const err = e as any
        if (err.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: toHex(NETWORK_CHAIN_ID),
                  rpcUrl: NETWORK_URL
                }
              ]
            })
          } catch (addError) {
            console.error(addError)
          }
        }
        console.error(error)
      }
    }
  }, [error])

  if (account) {
    return (
      <Web3StatusConnected
        id="web3-status-connected"
        onClick={handleToggleWalletModal}
        pending={hasPendingTransactions}
      >
        <WalletIconSVG />
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>{pending?.length} Pending</Text> <Loader stroke="white" />
          </RowBetween>
        ) : !isMobile ? (
          <>
            {hasSocks ? SOCK : null}
            <Text style={{ marginLeft: '.5rem' }}>{ENSName || shortenAddress(account, 4)}</Text>
          </>
        ) : null}
      </Web3StatusConnected>
    )
  } else if (error) {
    switchNetwork()
    return (
      <Web3StatusError onClick={handleToggleWalletModal}>
        <NetworkIcon />
        <Text>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}</Text>
      </Web3StatusError>
    )
  } else {
    return (
      <Web3StatusConnect id="connect-wallet" onClick={handleToggleWalletModal} faded={!account}>
        <WalletIconSVG />
        {!isMobile && <Text>{t('Connect Wallet')}</Text>}
      </Web3StatusConnect>
    )
  }
}

export default function Web3Status() {
  const { active, account } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmed = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      <Web3StatusInner />
      <WalletFlyout ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
