import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '..'
import { updatePrices } from './actions'

const SIPHER_API_URL = process.env.REACT_APP_SIPHER_API_URL || 'https://swap-api.sipher.gg/api'

export default function Updater(): null {
  const dispatch = useDispatch<AppDispatch>()
  const fetchPrices = useCallback(async () => {
    const response = await fetch(`${SIPHER_API_URL}/price/all`)
    return await response.json()
  }, [])

  useEffect(() => {
    fetchPrices().then(res => {
      if (res) {
        dispatch(updatePrices(res))
      }
    })
  }, [dispatch, fetchPrices])

  return null
}
